<template>
  <div>
    <b-modal
      id="edit-orderItem-materialVariants"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditMaterialVariants')"
      hide-footer
      size="xl"
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          :title="$t('MaterialVariants')"
          active
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("MaterialVariants") }}</strong>
          </template>
          <b-card-text><b-form>
            <div
              v-for="(material, index) in getMaterialVariantsOfOrder"
              :key="material.materialVariantId"
              :class="{ failed2: material.isStockAvailable === false }"
              class="material-info"
              style="
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 10px;
                            border: none;
                            box-shadow: none;
                            padding: 12px;
                            color: #212529;
                            border-radius: 8px;
                            width: 100%;
                            margin-right: 10px;
                            "
            >
              <!-- <b-button> -->
              <!-- Hover Me -->
              <div style="margin-top:10px;display: flex;justify-content: space-between;">
                <p style="color: #262E6C; font-weight: bold;">
                  {{ $t('Name') }}:</p> <p>{{ material.name }}</p>

              </div>
              <div class="hr" />
              <div style="margin-top:10px;display: flex;justify-content: space-between;">
                <p style="color: #262E6C; font-weight: bold;">
                  {{ $t('MeasurementType') }}:
                </p> <p>{{ material.measurementType }}</p>
              </div>
              <div class="hr" />
              <div style="margin-top:10px;display: flex; justify-content: space-between; align-items: baseline;">
                <p style="color:#262E6C; font-weight: bold;">
                  {{
                    material.length == 0
                      ? `${$t('Quantity')}:`
                      : `${$t('Length')}:`
                  }}
                </p>
                <!-- <b-form-input
                  id="input-1"
                  style="width: 30%;"
                /> -->
                <div
                  v-if="material.length"
                  style="margin-top: 10px; display: flex; justify-content: space-between;"
                >
                  <!-- <p style="color: #262E6C; font-weight: bold;">
                    {{ $t('New Length') }}:
                  </p> -->
                  <b-form-input
                    v-model.number="editedMaterialVariants[index].newLength"
                    v-b-tooltip.hover.top
                    :title="tooltipText"
                    placeholder="E.g 4 meters"
                    style="width: 100%;"
                  />
                </div>
                <div
                  v-else-if="material.quantity"
                  style="margin-top: 10px; display: flex; justify-content: space-between;"
                >
                  <!-- <p style="color: #262E6C; font-weight: bold;">
                    {{ $t('New Quantity') }}:
                  </p> -->
                  <b-form-input
                    v-model.number="editedMaterialVariants[index].newQuantity"
                    style="width: 100%;"
                  />
                </div>
                <p>
                  {{
                    material.length == 0
                      ? (material.quantity === 1 ? material.quantity + ` ${$t('Part')}` : material.quantity > 0 ? material.quantity + ` ${$t('Parts')}` : "")
                      : (material.length === 1 ? material.length + ` ${$t('Meter')}` : material.length > 0 ? material.length + ` ${$t('Meters')}` : "")
                  }}
                </p>
              </div>
            </div>

          </b-form>

          </b-card-text>
        </b-tab>
      </b-tabs>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          style="margin-left: 15px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          v-if="tabIndex == 2"
          :hidden="$route.path.includes('orders-overview')"
          style="margin-left: 15px;"
          :disabled="showSubmit == false"
          class="buttonSubmit"
          @click="onSubmitPrice"
        >
          {{ $t("Submit") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import flatPickr from 'vue-flatpickr-component';
// import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
import {
} from 'vuelidate/lib/validators';
import 'vue2-datepicker/index.css';

// import {
//   required, dateFormat, minLength, alpha, minValue, numeric, alphaNum, email,
// } from 'vuelidate/lib/validators';

export default {
  components: {
    // flatPickr,
    // DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
  props: ['order-item-id'],
  data() {
    return {
      tooltipText: 'Fill this one if there is more or less length/quantity ',
      editedMaterialVariants: [],
      showFrontBody: true,
      showFittingError: false,
      showSubmit: true,
      selectedCountry: null,
      editProbeName: false,
      countryOptions: [
        'Switzerland',
        'Germany',
        'France',
        'Austria',
        'Kosovo',
        'Albania',
        'North Macedonia',
        'Serbia',
        'Croatia', // Kosovo's ISO 3166-1 alpha-2 code is 'XK'
      ],
      tabIndex: 1,
      // probeDate: {},
      form: [],
      prices: {
        paidAmount: null,
        price: null,
        orderItemId: null,
      },
    };
  },
  validations: {
  },
  computed: {
    ...mapGetters([
      'getProbeDates',
      'getPrices',
      'getTwentyPrinciples',
      'getMaterialVariantsOfOrder',
      'getDescriptionOfOrder',
      'getOrderItemSize',
    ]),
  },
  watch: {
    form: {
      deep: true,
      handler(newForm) {
      // Check if all probeDates are filled
        const allProbeDatesFilled = newForm.every(({ date }) => { return date !== '' });

        if (allProbeDatesFilled) {
          this.showFittingError = false;
        }
      },
    },
    getProbeDates() {
      this.fillForm();
    },
    getPrices() {
      this.fillPrices();
    },
    'prices.paidAmount': {
      immediate: true,
      handler(newVal) {
        if (newVal > this.prices.price) {
          this.showSubmit = false;
        } else {
          this.showSubmit = true;
        }
      },
    },
    getMaterialVariantsOfOrder() {
      // Initialize editedMaterialVariants based on the current data
      this.editedMaterialVariants = this.getMaterialVariantsOfOrder.map((material) => {
        return {
          materialVariantId: material.materialVariantId,
          name: material.name,
          measurementType: material.measurementType,
          newLength: material.newLength,
          newQuantity: material.newQuantity,
          length: material.length,
          quantity: material.quantity,
          isStockAvailable: material.isStockAvailable,
          isEditable: material.isEditable,
        }
      });
      return this.getMaterialVariantsOfOrder;
    },
  },
  methods: {
    ...mapActions(['loadCountryStates', 'editPaidAmount', 'editOrAddProbeDate', 'delete_probeDate', 'loadProbeDates', 'loadPrices']),
    // async onSubmit() {
    //   const hasEmptyProbeDate = this.form.some(({ date }) => { return !date });

    //   if (hasEmptyProbeDate) {
    //     this.showFittingError = true
    //     return;
    //   }
    //   this.$refs.modal.hide();
    //   const updatedArray = this.form.map(({
    //     editable, probeDate, probeId, ...rest
    //   }) => {
    //     return { probeDateId: probeId, ...rest };
    //   });

    //   this.editOrAddProbeDate({
    //     object: updatedArray,
    //     successCallback: () => {
    //       // Your success callback logic here
    //     },
    //   });
    //   this.onReset();
    //   this.editProbeName = false;
    // },
    async onSubmit() {
      const payload = this.editedMaterialVariants.map((material) => {
        const newLength = material.newLength || 0;
        const newQuantity = material.newQuantity || 0;

        return {
          materialVariantId: material.materialVariantId,
          name: material.name,
          measurementType: material.measurementType,
          newLength,
          newQuantity,
          length: material.length,
          quantity: material.quantity,
          isStockAvailable: material.isStockAvailable,
          isEditable: material.isEditable,
        };
      });

      this.$store.dispatch('editVariantLength', {
        orderItemId: this.orderItemId,
        object: payload,
        successCallback: () => {
          // Success callback logic here
          this.$refs.modal.hide();
        },
      });
    },
    onSubmitPrice() {
      this.editPaidAmount({
        object: this.prices,
        successCallback: () => {
          this.$refs.modal.hide();
        },
      })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      // Trick to reset/clear native browser form validation state
    },
    addNewDate() {
      this.form.push(
        {
          probeName: `${this.$t('FitDate')} ${this.getProbeDates.length += 1}`,
          date: '',
          orderItemId: this.orderItemId,
          notes: null,
          isNew: true,
        },
      );
    },
    fillForm() {
      this.form = []
      for (let i = 0; i < this.getProbeDates.length; i++) {
        const date = this.getProbeDates[i].probeDate.substring(0, 10);
        const updatedItem = { ...this.getProbeDates[i], date };
        this.form.push(updatedItem);
      }
    },
    fillPrices() {
      this.prices = {
        ...this.getPrices,
      };
    },
    removeProbeDate(probeDate) {
      if (probeDate.isNew == true) {
        const index = this.form.findIndex((item) => { return item.probeId === probeDate.probeId });
        if (index !== -1) {
          this.form.splice(index, 1);
        }
      } else {
        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this fitting date?', {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
          .then((value) => {
            if (value) {
              this.delete_probeDate({
                probeId: probeDate.probeId,
                successCallback: () => {
                  this.loadProbeDates(this.orderItemId);
                },
              });
            }
          })
          .catch((err) => {
            console.log('Error in msgBoxConfirm:', err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.failed2 {
  background-color: rgb(255, 95, 95);
  color: white !important;
  .hr {
  background-color: white;
min-width: 201px;
height: 1px;
/* UI Properties */
border: 0.5px solid white;
opacity: 0.74;
}
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: flex-end;
}
.form2 {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  gap: 10px;
}
.hr{
  background-color: grey;
min-width: 201px;
height: 1px;
/* UI Properties */
border: 0.5px solid #A5A5A5;
opacity: 0.34;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.headline {
  padding: 5px 10px;
  background-color: $base-color;
  color: $digit-white;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}
.front-body {
  position: relative;
  img {
    width: 100%;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background-color: #6c757d;;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
@media only screen and (max-width: 1200px){
  .front-body {
  position: relative;
  .toraks {
    top: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    position: absolute;
    top: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    position: absolute;
    top: 125px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .beli {
    position: absolute;
    top: 155px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    position: absolute;
    top:249px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    position: absolute;
    bottom: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    position: absolute;
    bottom: 35px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    position: absolute;
    bottom: 5px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    position: absolute;
    top: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    position: absolute;
    top: 125px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    position: absolute;
    top: 155px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    position: absolute;
    top: 95px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    position: absolute;
    bottom: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    position: absolute;
    bottom: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    position: absolute;
    bottom: 35px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    position: absolute;
    bottom: 5px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background-color: #6c757d;;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    position: absolute;
    top: 126px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    position: absolute;
    top: 156px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    position: absolute;
    top: 192px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    position: absolute;
    top: 70px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    position: absolute;
    top: 215px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .supet {
    position: absolute;
    top: 97px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    position: absolute;
    top: 70px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    position: absolute;
    bottom: 100px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    position: absolute;
    top: 98px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
}
input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
    top: 195px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
    }
  }
}
</style>
