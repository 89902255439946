<template>
  <div>
    <b-modal
      id="modal-assign-employee"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AssignEmployee')"
      hide-footer
      @close="onCancel"
    >
      <b-form style="display: flex; flex-direction:column">
        <vue-select
          v-model="form.processingUserId"
          :options="vacationUsers"
          :reduce="(e) => e.userId"
          :placeholder="$t('SelectEmployee')"
          label="label"
          aria-describedby="input-1-live-feedback"
          @input="handleUserSelection"
        >
          <template
            slot="option"
            slot-scope="option"
          >
            <span :style="{ color: option.isOnVacation ? 'red' : 'inherit' }">
              {{ option.label }}
            </span>
          </template>
        </vue-select>

        <!-- Warning message -->
        <div
          v-if="showWarning"
          style="color: red;"
        >
          {{ $t("UserVacation") }}
        </div>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Notes')}:`"
          label-for="input-1"
        >
          <b-form-textarea
            id="input-1"
            v-model="form.message"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>

        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Yes') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex'


export default {
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['itemId'],
  data() {
    return {
      showWarning: false,
      form: {
        orderItemId: this.itemId,
        message: '',
        processingUserId: null,
      },

    }
  },
  validations: {
    form: {
      description: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(['getTeamsNames', 'getDropdownTeamName', 'getLocationsByType', 'getTeamOverview']),
    vacationUsers() {
      if (this.getTeamOverview && this.getTeamOverview.users) {
        return this.getTeamOverview.users.map((user) => {
          return {
            userId: user.userId,
            label: user.name,
            isOnVacation: user.isOnVacation,
          }
        });
      }
      return [];
    },
  },
  watch: {
    itemId(value) {
      this.form.orderItemId = value;
    },
  },
  methods: {
    ...mapActions([
      'teamByTeamNameId',
    ]),
    handleUserSelection() {
      const selectedUser = this.vacationUsers.find(
        (user) => { return user.userId === this.form.processingUserId },
      );

      if (selectedUser && selectedUser.isOnVacation) {
        // Show the warning if the selected user is on vacation
        this.showWarning = true;
      } else {
        // Hide the warning if the selected user is not on vacation
        this.showWarning = false;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      this.$nextTick(() => {
        this.$emit('assignEmployee', this.form);
        this.$refs.modal.hide();
        this.onCancel()
      });
    },
    onCancel() {
      this.$refs.modal.hide()
      this.form.message = ''
      this.form.message = '';
      this.form.processingUserId = null;
      this.showWarning = false

      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .buttonat{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
</style>
